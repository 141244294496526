import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

import client from 'src/config/client';
import isEmpty from 'src/utils/is-empty';

const userSlice = createSlice({
  name: 'user',
  initialState: {},
  reducers: {
    setUser: (state, { payload }) => {
      const { token, username } = payload;
      if (typeof token !== 'object' && typeof token !== 'undefined') {
        const decoded = jwtDecode(token);
        if (username) {
          localStorage.setItem(username, token);
        } else {
          localStorage.setItem('jwtToken', token);
        }
        return decoded;
      }
      return {};
    },
    logoutUser: (state, { payload }) => {
      const { username } = payload;
      client.cache.reset();
      if (!username) {
        localStorage.removeItem('jwtToken');
      } else {
        localStorage.removeItem(username);
      }
      return {};
    },
  },
});

const authSlice = createSlice({
  name: 'isAuthenticated',
  initialState: false,
  reducers: {},
  extraReducers: {
    [userSlice.actions.setUser]: (state, { payload }) => !isEmpty(payload),
    [userSlice.actions.logoutUser]: (state, { payload }) => !isEmpty(payload),
  },
});

export const { setUser, logoutUser } = userSlice.actions;

export default {
  user: userSlice.reducer,
  isAuthenticated: authSlice.reducer,
};
