import { createSlice } from '@reduxjs/toolkit';

const scrollerUsabilitySlice = createSlice({
  name: 'canUseScroller',
  initialState: true,
  reducers: {
    setScrollerUsability: (state, { payload }) => {
      return payload;
    },
  },
});

export const { setScrollerUsability } = scrollerUsabilitySlice.actions;

export default {
  scrollerUsability: scrollerUsabilitySlice.reducer,
};
