import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
  name: 'users',
  initialState: [],
  reducers: {
    setUsers: (state, { payload }) => {
      return payload;
    },
  },
});

const externalUsersSlice = createSlice({
  name: 'externalUsers',
  initialState: [],
  reducers: {
    setExternalUsers: (state, { payload }) => {
      return payload;
    },
  },
});

const teknikUsersSlice = createSlice({
  name: 'teknikUsers',
  initialState: [],
  reducers: {
    setTeknikUsers: (state, { payload }) => {
      return payload;
    },
  },
});

const totalUsersSlice = createSlice({
  name: 'totalUsers',
  initialState: 0,
  reducers: {
    setTotalUsers: (state, { payload }) => {
      return payload;
    },
  },
});

export const { setUsers } = usersSlice.actions;
export const { setTotalUsers } = totalUsersSlice.actions;
export const { setExternalUsers } = externalUsersSlice.actions;
export const { setTeknikUsers } = teknikUsersSlice.actions;

export default {
  users: usersSlice.reducer,
  totalUsers: totalUsersSlice.reducer,
  externalUsers: externalUsersSlice.reducer,
  teknikUsers: teknikUsersSlice.reducer,
};
