import React from 'react';
import Spin from 'antd/lib/spin';
import 'antd/lib/spin/style';

import './style.less';

const Fallback = () => {
  return (
    <div className="loading-spinner">
      <Spin tip="Loading..." />
    </div>
  );
};

export default Fallback;
