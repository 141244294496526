import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import moment from 'moment';

const getTime = (time) => {
  TimeAgo.addLocale(en);
  const timeAgo = new TimeAgo();
  const Time = Date.now() - new Date(time).getTime();
  return timeAgo.format(Date.now() - Time);
};

export const formatDate = (time) => {
  let t = moment(time).toDate();
  const offset = Math.abs(t.getTimezoneOffset() / 60);
  t.setHours(offset);
  t.setMinutes(0);
  t.setSeconds(0);
  t.setMilliseconds(0);
  return moment(t).toISOString();
};

export const getHours = (startTime, endTime) => {
  const start = moment(startTime, 'HH:mm');
  const end = moment(endTime, 'HH:mm');
  const isSameDay = start.isBefore(end);
  if (isSameDay) {
    const duration = moment.duration(end.diff(start));
    const h = duration.asHours();
    return `${h}`;
  }
  const anotherDay = end.add(1, 'day');
  const duration = moment.duration(anotherDay.diff(start));
  const h = duration.asHours();
  return `${h}`;
};

export const getNextDay = (time) => {
  return formatDate(moment(time).add(1, 'days').format());
};

export const getPrevDay = (time) => {
  return formatDate(moment(time).add(-1, 'days').format());
};

export default getTime;
