import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';

import client from 'src/config/client';
import configureStore from 'src/state/store';
import { Translator, Config } from 'react-translator-component';

import App from 'src/views/App';
import './index.less';

function Container() {
  const reduxStore = configureStore(window.REDUX_INITIAL_DATA);
  Config.default = 'sw';
  Config.list = {
    en: {
      text: 'English',
      icon: '/flags/en.svg',
      file: require('./locale/en.js'),
    },
    sw: {
      text: 'Swedish',
      icon: '/flags/sw.svg',
      file: require('./locale/sw.js'),
    },
  };

  return (
    <ApolloProvider client={client}>
      <Router>
        <Provider store={reduxStore}>
          <Translator>
            <App />
          </Translator>
        </Provider>
      </Router>
    </ApolloProvider>
  );
}

ReactDOM.render(<Container />, document.getElementById('root'));
