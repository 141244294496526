/* eslint-disable */
import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Layout, Result, Button } from 'antd';

import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
  Redirect,
} from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';

import { setUser, logoutUser } from 'src/state/ducks/auth';
import client from 'src/config/client';
import { ME } from 'src/resolvers/user/query';

import Fallback from 'src/components/Fallback';

const UserRoutes = lazy(() => import('../User'));
const SignIn = lazy(() => import('../SignIn'));
const ResetPassword = lazy(() => import('../ResetPassword'));

const App = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  async function checkAuth() {
    try {
      if (localStorage.jwtToken) {
        const decoded = jwtDecode(localStorage.jwtToken);
        const currentTime = Date.now() / 1000;

        if (decoded.exp < currentTime) {
          return logout();
        }

        // verify token with server
        const response = await client.query({
          query: ME,
        });
        const user = response?.data.me;

        if (user) {
          return login({ token: localStorage.jwtToken });
        }
        return removeUserData();
      }
    } catch (error) {
      if ({ error }.error.message === 'Network error: Failed to fetch') return;
      removeUserData();
    }
  }

  /**
   *
   * @param {String} param0 jwt token
   */
  async function login({ token }) {
    props.setUser({ token });
  }

  async function removeUserData() {
    props.logoutUser({});
  }

  async function logout() {
    await removeUserData();
    history.push('/signin');
  }

  useEffect(() => {
    async function check() {
      await checkAuth();
      setLoading(false);
    }
    check();
  }, []);

  if (loading) return <Fallback />
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Suspense fallback={<Fallback />}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/user" />
            </Route>
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/reset" component={ResetPassword} />
            <Route path="/user" component={UserRoutes} />
            <Route
              render={() => (
                <Result
                  status="404"
                  title="404"
                  subTitle="Sorry, the page you visited does not exist."
                  extra={
                    <Button type="primary" href="/">
                      Back Home
                    </Button>
                  }
                />
              )}
            />
          </Switch>
        </Router>
      </Suspense>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.isAuthenticated,
});

export default connect(mapStateToProps, { setUser, logoutUser })(
  withApollo(withRouter(App))
);
