module.exports = {
  /* SideBar and header */
  "Log in": "Logga in",
  "Statistics": "Statistik",
  "Posts": "Inlägg",
  "Post List": "Alla inlägg",
  "Work Schedule": "Produktionsschema",
  "Users": "Användare",
  "Create Group": "Skapa grupp",
  "Group List": "Grupplista",
  "Settings": "Inställningar",
  "Logout": "Logga ut",
  /* SideBar and header */

  /* Statistics Page */
  "Produced goods this year": "Antal producerade bröd I år",
  "Amount of employees": "Antal anställda",
  "Productivity": "Produktivitet",
  "Production Numbers": "Produktionssiffror",
  "Posters of this year": "Inläggsskapare detta året",
  "Amount of problems with equipment": "Antal problem med utrustningar",
  "TOP AUTHOR": "Toppförfattare",
  "Production goals achieved": "Uppnådda produktionsmål",
  /* Statistics Page */ 

  /* Posts Page */
  "Write Post": "Skapa inlägg",
  "Write Admin Post": "Skapa Chefsinlägg",
  "Create Admin Post": "Skapa Chefsinlägg",
  "Create Post": "Skapa inlägg",
  "Post": "Inlägg",
  "Workorder": "Arbetsorder",
  "Place": "Bana",
  "Publish Now": "Publicera nu",
  "Submit": "Skicka",
  "Body": "Text",
  "Latest Posts from Admin": "Senaste allmänna inlägg",
  "Latest Posts": "Senaste förbättringsinlägg",
  "Date": "Datum",
  "Title": "Titel",
  "Author": "Författare",
  "Description": "Beskrivning",
  "Action": "Åtgärd",
  "Read More": "Läs mer",
  "Ok": "Ok",
  "replies": "svar",
  "Add Comment": "Kommentera",
  "All Admin Posts": "Alla allmänna inlägg",
  "All Posts": "Alla inlägg",
  "Edit Post": "Redigera Inlägg",
  "Published": "Publicerad",
  "Unpublished": "Opublicerad",
  "Cancel": "Avbryt",
  /* Posts Page */

  /* Workschedule Page */
  "Create": "Skapa",
  "Schedule": "Produktionsschema",
  "Time": "Tid",
  "Mechanics": "Bageri",
  "Workers": "Packen",
  "Start Time": "Start-tid",
  "End Time": "Slut-tid",
  "No one is assigned to this task": "Ingen personal angiven",
  "Assign": "Ange personal",
  "Update WorkSchedule": "Uppdatera produktionsschema",
  "Update Profile": "Uppdatera Profil",
  "Update": "Uppdatera",
  "Profile": "Profil",
  "Search": "Sök",
  "Reset": "Återställa",
  "Assign Users": "Ange personal",
  "Mechanics Groups": "Bageri Grupper",
  "Workers Groups": "Packen Grupper",
  "Age": "Ålder",
  "Phone": "Telefon",
  "Name": "Namn",
  "Position": "Position",
  "Delete": "Radera",
  /* Workschedule Page */

  /* Users Page */
  "Create User": "Skapa användare",
  "Create WorkSchedule": "Skapa produktionsschema",
  "All Users": "Alla användare",
  "Edit User": "Redigera användare",
  "Password": "Lösenord",
  "Confirm": "Bekräfta",
  "Confirm Password": "Bekräfta Lösenord",
  /* Users Page */
  
  /* Group List Page */
  "All Premade Groups": "Alla grupper",
  /* Group List Page */
};