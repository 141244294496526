import gql from 'graphql-tag';

export const ME = gql`
  query ME {
    me {
      id
      email
      name
    }
  }
`;

export const GET_USERS = gql`
  query GET_USERS {
    users {
      id
      email
      name
      phone
      position
    }
    totalUsers
  }
`;

export const ALL_USERS = gql`
  query ALL_USERS {
    allUsers {
      id
      email
      name
      phone
      position
    }
  }
`;

export const GET_EXTERNAL_USERS = gql`
  query GET_EXTERNAL_USERS {
    externalUsers {
      id
      email
      name
      phone
      position
    }
    totalExternalUsers
  }
`;

export const TOTAL_USERS = gql`
  query TOTAL_USERS {
    totalUsers
  }
`;
